import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useState} from "react";
import {HotelSpreadsheet} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode} from "primereact/api";
import {Tooltip} from "primereact/tooltip";
import EditHotelSpreadsheetDialog from "./EditHotelSpreadsheetDialog";
import AddHotelSpreadsheetDialog from "./AddHotelSpreadsheetDialog";


export default function HotelsSpreadsheetPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filters, setFilters] = useState<DataTableFilterMeta>({global: { value: null, matchMode: FilterMatchMode.CONTAINS}});
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [data, setData] = useState<HotelSpreadsheet[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [reload, setReload] = useState<boolean>(false);

    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [selectedHotel, setSelectedHotel] = useState<HotelSpreadsheet | undefined>(undefined);

    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);


    useEffect(() => {
        getJsonFromBackend("/hotels-spreadsheet/get")
            .then((rsp: HotelSpreadsheet[]) => {
                setData(rsp);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting hotels data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload])


    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
                <div className="ml-auto">
                    <i
                        className="fa-solid fa-plus fa-lg hover:cursor-pointer hover:text-green-700"
                        onClick={() => setShowAddDialog(true)}
                    />
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    function renderRooms(item: HotelSpreadsheet){
        return <div className={`truncate guest-name-target-${item.id} cursor-pointer m-w-fit`}>
            <i className="fa-solid fa-door-closed"/>
            <Tooltip target={`.guest-name-target-${item.id}`} position="left">
                <div className="flex flex-col">
                    {item.rooms.map(room => {
                        return <span key={room.id}>{room.name} ({room.hotelTax} / {room.hotelLodgingTax})</span>
                    })}
                </div>
            </Tooltip>
        </div>
    }

    function renderRocketstayShare(item: HotelSpreadsheet) {
        return <>{item.rocketstayShare}%</>
    }

    function renderAirbnbCom(item: HotelSpreadsheet) {
        return <>{item.airbnbCom}%</>
    }

    function renderTaxPercent(item: HotelSpreadsheet) {
        return <>{item.taxPercent}%</>
    }

    function renderTax(item: HotelSpreadsheet) {
        return <>{item.tax}%</>
    }

    function renderTaxPerNightPercent(item: HotelSpreadsheet) {
        return <>{item.taxPerNightPercent}%</>
    }

    function renderResortFeePercent(item: HotelSpreadsheet) {
        return <>{item.resortFeePercent}%</>
    }

    function renderPassThrough(item: HotelSpreadsheet) {
        return <>{item.passThrough}%</>
    }


    function renderActions(item: HotelSpreadsheet){
        return <>
            <i
                className="fa-solid fa-pen-to-square text-green-500 hover:cursor-pointer hover:text-green-600"
                onClick={() => {
                    setSelectedHotel(item);
                    setShowEditDialog(true);
                }}
            />

            <i
                className="fa-solid fa-trash text-red-500 hover:cursor-pointer hover:text-red-600 ml-2"
                onClick={() => removeHotel(item)}
            />

        </>
    }

    async function removeHotel(hotel: HotelSpreadsheet) {
        if(!window.confirm(`Are you sure you want to remove ${hotel.name}?`)){
            return;
        }
        try {
            await postJsonToBackend("/hotels-spreadsheet/remove", hotel.id);
            showToastMessage("Success", "Hotel removed", "success");
            setReload(!reload);
        } catch (err){
            showToastMessage("Error", "Something bad happened", "error");
        }

    }



    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="hotel-spreadsheet-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ?
                <DataTable
                    value={data}
                    first={first}
                    filters= {filters}
                    rows={pageSize}
                    paginator
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    emptyMessage="No data found."
                    tableStyle={{minWidth: '50rem'}}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    header= {renderHeader()}
                    pt={{
                        header: {className: "!bg-white !border-0"},
                    }}
                >
                    <Column
                        className="!bg-white "
                        field="id"
                        header="Hotel ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="name"
                        header="Hotel"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="propertyId"
                        header="Property ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="category"
                        header="Category"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="taxPercent"
                        header="Sales Tax %"
                        body={renderTaxPercent}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="tax"*/}
                    {/*    header="Tax/VAT USD ($)"*/}
                    {/*    body={renderTax}*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        field="taxPerNight"
                        header="Hotel Tax/Night"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="taxPerNightPercent"*/}
                    {/*    header="Tax/Night USD (%)"*/}
                    {/*    body={renderTaxPerNightPercent}*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="resortFeePercent"*/}
                    {/*    header="Resort Fee/night (%)"*/}
                    {/*    body={renderResortFeePercent}*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="resortFee"*/}
                    {/*    header="Resort Fee/night ($)"*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        field="airbnbCom"
                        header="Airbnb Com %"
                        body={renderAirbnbCom}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="rocketstayShare"
                        header="Rocketstay Share %"
                        body={renderRocketstayShare}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="passThrough"
                        header="Pass Through %"
                        body={renderPassThrough}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="lodgingTaxDaily"
                        header="Lodging Tax Daily"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />


                    <Column
                        className="!bg-white "
                        field="pms"
                        header="PMS"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="accessKey"
                        header="Access Key"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="rooms"
                        header="Rooms"
                        body={renderRooms}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="currency"
                        header="Currency"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        header="Actions"
                        body={renderActions}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                </DataTable> : <></>
            }
        </div>

        {selectedHotel && <EditHotelSpreadsheetDialog
            showEditDialog={showEditDialog}
            selectedHotel={selectedHotel}
            onClose={() => {
                setSelectedHotel(undefined);
                setShowEditDialog(false);
            }}
            triggerReload={() => setReload(!reload)}
        />}

        {showAddDialog && <AddHotelSpreadsheetDialog
            showAddDialog={showAddDialog}
            onClose={() => {
                setSelectedHotel(undefined);
                setShowAddDialog(false);
            }}
            triggerReload={() => setReload(!reload)}
        />}


    </div>
}